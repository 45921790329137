import React from 'react';
import '../assets/styles/style.css'; 
import NoteIcon from '../assets/images/Note.png';
import PriceTagIcon from '../assets/images/Price Tag USD.png';
import PlaneIcon from '../assets/images/Plane.png';
import TrekkingIcon from '../assets/images/Trekking.png';
import EmptyBedIcon from '../assets/images/Empty Bed.png';
import CarRoofBoxIcon from '../assets/images/Car Roof Box.png';

const WhyChooseUs = () => {
  return (
    <section id="why-choose-us">
      <h2>Why choose us?</h2>
      <div className="features">
        <div className="feature bg-green">
          <div className="icon"><img src={NoteIcon} alt="Tailored made tours" /></div>
          <p>Tailored made tours</p>
        </div>
        <div className="feature bg-yellow">
          <div className="icon"><img src={PriceTagIcon} alt="Great prices" /></div>
          <p>Great prices</p>
        </div>
        <div className="feature bg-green">
          <div className="icon"><img src={PlaneIcon} alt="Airport transfers" /></div>
          <p>Airport transfers</p>
        </div>
        <div className="feature bg-yellow">
          <div className="icon"><img src={TrekkingIcon} alt="Personal Tour guide" /></div>
          <p>Personal Tour guide</p>
        </div>
        <div className="feature bg-green">
          <div className="icon"><img src={EmptyBedIcon} alt="Hotel Booking" /></div>
          <p>Hotel Booking</p>
        </div>
        <div className="feature bg-yellow">
          <div className="icon"><img src={CarRoofBoxIcon} alt="Transportation" /></div>
          <p>Transportation</p>
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;