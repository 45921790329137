import React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FAQItem = ({ question, answer, isOpen, onClick }) => (
  <div className="item">
    <div className="faq-question" onClick={onClick}>
      <h3>{question}</h3>
      {isOpen ? <FaChevronUp color="white" /> : <FaChevronDown color="white" />}
    </div>
    {isOpen && <div className="faq-answer"><p>{answer}</p></div>}
  </div>
);

export default FAQItem;
