import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/style.css'; 
import { FaBars } from "react-icons/fa";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
    return (
    <header>
       <div className="top">
      <nav>
        <div className="logo">
          <Link to="/">SONG & STARK TOURS</Link>
        </div>
        <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/tours">Tour packages</Link></li>
          <li><Link to="/about">About us</Link></li>
          <li className="btn-yellow"><Link to="/plan-trip">Plan your Trip</Link></li>
          <li className="btn-green"><Link to="/contact">Contact us</Link></li>
        </ul>
      </nav>
      <div className="menuToggleBtn" onClick={toggleMenu}>
          <FaBars/>
      </div>
      </div>
    </header>
  );
}

export default Header;