import React from "react";
import Header from "../components/Header";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import "../assets/styles/contactUs.css"; // 필요한 경우 CSS 경로 수정

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="contact-context">
        <h1>Contact us!</h1>
        <p>Any question or remarks? Want to book? Just write us a message!</p>
        <ContactForm />
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
