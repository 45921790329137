import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/style.css'; 
import RwandaImage from '../assets/images/rwanda.png';
import UgandaImage from '../assets/images/uganda.png';
import KenyaImage from '../assets/images/kenya.png';
import TanzaniaImage from '../assets/images/tanzania.png';

const Destinations = () => {
  const navigate = useNavigate();

  const handleCardClick = (country) => {
    navigate(`/tours?country=${country}`);
  };

  return (
    <section className="destinations">
      <div className="container">
        <h2>Destinations</h2>
        <div className="card-container" id="destinations">
          <div className="card" onClick={() => handleCardClick('Rwanda')}>
            <img src={RwandaImage} alt="Rwanda" />
            <div className="card-title">Rwanda</div>
          </div>
          <div className="card" onClick={() => handleCardClick('Uganda')}>
            <img src={UgandaImage} alt="Uganda" />
            <div className="card-title">Uganda</div>
          </div>
          <div className="card" onClick={() => handleCardClick('Kenya')}>
            <img src={KenyaImage} alt="Kenya" />
            <div className="card-title">Kenya</div>
          </div>
          <div className="card" onClick={() => handleCardClick('Tanzania')}>
            <img src={TanzaniaImage} alt="Tanzania" />
            <div className="card-title">Tanzania</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Destinations;