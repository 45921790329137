import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import WhyChooseUs from "../components/WhyChooseUs";
import PopularTours from "../components/PopularTours";
import Destinations from "../components/Destinations";
import Activities from "../components/Activities";
import Banner from "../components/Banner";
import Articles from "../components/Articles";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div className="container">
        <Header />
        <Hero />
        <WhyChooseUs />
        <div className="popular-tours-title">
          <h2>Popular tours</h2>
          <Link to="/tours" className="view-all-link">
            View all
          </Link>
        </div>
        <PopularTours />
      </div>
      <div className="container-full">
        <Destinations />
      </div>
      <div className="container">
        <Activities />
        <Banner />
        <Articles />
      </div>
      <div className="container-full">
        <Footer />
      </div>
    </>
  );
};

export default Home;
