import React from 'react';
import '../assets/styles/style.css'; 
import NationalParksImage from '../assets/images/national-parks.png';
import LakeActivitiesImage from '../assets/images/lake-activities.png';
import CityToursImage from '../assets/images/city-tours.png';
import FoodImage from '../assets/images/food.png';
import HikingImage from '../assets/images/hiking.png';
import DanceImage from '../assets/images/dance.png';

const Activities = () => {
  return (
    <section className="section activities">
      <h2>Activities</h2>
      <div className="list-container">
        <div className="list" style={{ gridArea: 'national-parks' }}>
          <img src={NationalParksImage} alt="National parks" />
          <div className="list-title">National parks</div>
        </div>
        <div className="list" style={{ gridArea: 'lake-activities' }}>
          <img src={LakeActivitiesImage} alt="Lake activities" />
          <div className="list-title">Lake activities</div>
        </div>
        <div className="list" style={{ gridArea: 'city-tours' }}>
          <img src={CityToursImage} alt="City tours" />
          <div className="list-title">City tours</div>
        </div>
        <div className="list" style={{ gridArea: 'food' }}>
          <img src={FoodImage} alt="Food" />
          <div className="list-title">Food</div>
        </div>
        <div className="list" style={{ gridArea: 'hiking' }}>
          <img src={HikingImage} alt="Hiking" />
          <div className="list-title">Hiking</div>
        </div>
        <div className="list" style={{ gridArea: 'dance' }}>
          <img src={DanceImage} alt="Dance" />
          <div className="list-title">Dance</div>
        </div>
      </div>  
    </section>
  );
}

export default Activities;