import React from 'react';
import '../assets/styles/style.css'; 
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <>
    <section id="hero">
      <div className="overlay">
        <h1>Explore the wild with Us!</h1>
        <p>Explore the wild with us</p>
        <Link to="/plan-trip">
          <button className="btn-yellow">Book Now</button>
        </Link>
      </div>
    </section>
    <div className="wave"></div>
    </>
  );
}

export default Hero;