export const faqData = [
    {
      question: "What is Rwanda like?",
      answer: "Rwanda is a small country in East Africa. For many people, this land is still remembered for the 1994 genocide, but 20 years later, it is a fast-developing, safe country with some of the most advanced policies in Africa. Rwanda, also known as the 'Land of a Thousand Hills,' is a country of fertile land, large and small hills, and blue lakes."
    },
    {
      question: "Is Rwanda safe to travel?",
      answer: "Rwanda is regarded as one of the safest countries to visit in Africa. Thousands of tourists visit Rwanda each year, mainly for gorilla trekking safaris and they're generally warmly welcomed and made to feel safe and secure in the country."
    },
    {
      question: "What's the weather like in Rwanda?",
      answer: "Rwanda enjoys a tropical temperate climate. The average annual temperature ranges between 16 and 20 C, without significant variations. Rainfall is abundant although it has some irregularities."
    }
  ];
  