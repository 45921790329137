import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FAQItem from '../components/FAQItem';
import { faqData } from '../assets/data/faqData';
import RttaImage from '../assets/images/rtta.png';
import RdbImage from '../assets/images/rdb.png';
import AkageraImage from '../assets/images/akagera.png';
import '../assets/styles/about.css';
import { Link } from 'react-router-dom';

const About = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const openQuestion = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div className="container">
        <Header />
        <section>
          <div id="aboutus">
            <h1>About Us</h1>
            <p>
              Africa World Tour is a reputable Tour agency operating in Rwanda offering private and tailor-made tours to tourists. We are registered and a member of Rwanda Tours and Travel Association. Our agency stands apart with well-designed itineraries, excellent customer services and adventures in the wild, you will carry unforgettable memories of Rwanda’s beauty and wildlife with you.
              <br />
              <br />
              Our tour packages are Mid-range and Luxurious, based on your budget a tour package can be arranged. We boost in offering excellent customer services, ease in making bookings for your trip thus customer satisfaction is guaranteed. Our partnership with different hotels, restaurants, resorts and lodges gives us an edge in offering excellent accommodation bookings while on tour as we are given priority when it comes to booking the best hotel rooms.
            </p>
          </div>
        </section>
        <div id="faq">
          <div className="content">
            <h2>FAQs</h2>
            {faqData.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                isOpen={expandedIndex === index}
                onClick={() => openQuestion(index)}
              />
            ))}               
            <div className="contact">
              <span>My question is not here.</span>
              <Link to="/contact" className="btn-yellow link">Contact us</Link>
            </div>
          </div>
        </div>
        <section id="ourpartners">
          <h3>Partners</h3>
          <div className="partners">
            <img src={RttaImage} alt="RTTA Logo" />
            <img src={RdbImage} alt="RDB Logo" />
            <img src={AkageraImage} alt="AKAGERA AVIATION Logo" />
          </div>
        </section>
      </div>
      <div className="container-full">
        <Footer />
      </div>
    </>
  );
};

export default About;
