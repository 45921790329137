import React, { useState } from "react";
import "../assets/styles/contactForm.css";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { API_URL } from '../variables';

const api_url = `${API_URL}/contacts/createContact`;

const ContactForm = () => {
  const [names, setNames] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nationality, setNationality] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!names || !email || ! phone) {
      toast.error("Please fill out all required fields.");
      return;
    }

    const payload = {
      names,
      email,
      phone,
      nationality,
      message,
    };

    try {
      const response = await axios.post(api_url, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        toast.success(`Thanks for contacting us we'll get back to you very soon at ${email}`,{duration: 3000,position: 'bottom-center',});
        setNames("");
        setEmail("");
        setPhone("");
        setNationality("");
        setMessage("");
      }
    } catch (error) {
      toast.error("Failed to send message. Please try again.");
    }
  };

  return (
    <div className="contact-form-container">
      <div className="contact-info">
        <h2>Contact Information</h2>
        <p>Say something to start a live chat!</p>
        <ul>
          <li>
            <FaPhone /> +250 794-422-747
          </li>
          <li>
            <FaEnvelope /> <a href="mailto:help@songstarktours.com" className="email-link">help@songstarktours.com</a>
          </li>
          <li>
            <FaMapMarkerAlt /> 5th Floor-506, Ikaze House, Kisementi. Remera.
          </li>
        </ul>
      </div>
      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="names">Name</label>
            <input
              type="text"
              id="names"
              name="names"
              value={names}
              onChange={(e) => setNames(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="nationality">Nationality</label>
            <input
              type="text"
              id="nationality"
              name="nationality"
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="form-group button-group">
            <button type="submit">Send a Message</button>
          </div>
        </form>
      </div>
      <Toaster />
    </div>
  );
};

export default ContactForm;
