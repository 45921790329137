import React, { createContext, useContext } from 'react';

const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const BASE_URL = 'https://songstarktours.s3.ap-northeast-2.amazonaws.com/';

  const getImageUrl = (imageName) => {
    return imageName ? `${BASE_URL}${imageName}` : '';
  };

  return (
    <ImageContext.Provider value={{ getImageUrl }}>
      {children}
    </ImageContext.Provider>
  );
};

export const useImage = () => useContext(ImageContext);
