import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "../assets/styles/tripSurvey.css";
import { API_URL } from "../variables";

const api_url = `${API_URL}/custom_reservations/createReservation`;

// Array of activities with their corresponding IDs
const activities = [
  { id: 1, name: "Gorilla Tracking" },
  { id: 2, name: "Chimpanzee Tracking" },
  { id: 3, name: "Golden Monkey Tracking" },
  { id: 4, name: "Games Drives" },
  { id: 5, name: "Cultural Safaris" },
  { id: 6, name: "Easy to Moderate Hiking" },
  { id: 7, name: "Moderate to Difficult Hiking" },
  { id: 8, name: "Boat trips" },
  { id: 9, name: "Visiting Community Projects" },
];

const TripSurvey = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    arrivalDate: '',
    departureDate: '',
    interests: [], // Store selected activity_ids
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      const activityId = parseInt(value); // Ensure the ID is an integer

      setFormData((prev) => ({
        ...prev,
        interests: checked
          ? [...prev.interests, activityId] // Add the activity ID
          : prev.interests.filter((interest) => interest !== activityId), // Remove the activity ID
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const reservationData = {
      reservation_first_name: formData.firstName,
      reservation_last_name: formData.lastName,
      reservation_email: formData.email,
      reservation_phone: formData.phone,
      arrival_date: formData.arrivalDate,
      departure_date: formData.departureDate,
      reservation_interests: formData.interests, // Send activity IDs array
      reservation_date: new Date().toISOString().split('T')[0],
    };

    try {
      const response = await axios.post(
        api_url,
        JSON.stringify(reservationData),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success("Reservation made successfully!");
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          arrivalDate: '',
          departureDate: '',
          interests: [], // Reset interests
        });
      }
    } catch (error) {
      console.error("Error submitting reservation", error);
      toast.error("There was an issue submitting your reservation. Please try again.");
    }
  };

  return (
    <div className="trip-survey">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="interests">
        <h2>Interests</h2>
        <ul>
          {activities.map(({ id, name }) => (
            <li key={id}>
              <input
                type="checkbox"
                id={name}
                name="interests"
                value={id} // Use activity ID as value
                checked={formData.interests.includes(id)} // Check by activity ID
                onChange={handleChange}
              />
              <label htmlFor={name}>{name}</label>
            </li>
          ))}
        </ul>
      </div>
      <div className="form-section">
        <div className="form-group">
          <label htmlFor="first-name">First Name</label>
          <input
            type="text"
            id="first-name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="last-name">Last Name</label>
          <input
            type="text"
            id="last-name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="arrival-date">Arrival date</label>
          <input
            type="date"
            id="arrival-date"
            name="arrivalDate"
            value={formData.arrivalDate}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="departure-date">Departure date</label>
          <input
            type="date"
            id="departure-date"
            name="departureDate"
            value={formData.departureDate}
            onChange={handleChange}
          />
        </div>
        <button type="submit" onClick={handleSubmit}>Book Now</button>
      </div>
    </div>
  );
};

export default TripSurvey;
