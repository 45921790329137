import React, { useState, useEffect } from "react";
import { Link} from "react-router-dom";
import axios from "axios";
import "../assets/styles/tours.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { API_URL } from "../variables";
import { getToken } from '../authService';  
import { useImage } from "../components/ImageContext";

const api_url = `${API_URL}/package/getAllPackages`;

const Tours = () => {
  const { getImageUrl } = useImage();
  const [tours, setTours] = useState([]);
  const [filters, setFilters] = useState({
    type: [],
    country: [],
    duration: [],
    maxPrice: null,
    rating: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [toursPerPage] = useState(5);

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const token = getToken();
        const response = await axios.get(api_url, {
          headers: {
            'Authorization': `Bearer ${token}`,  
            'Content-Type': 'application/json'
          },
        });

        const validTours = response.data.filter((tour) => 
          tour.package_title && tour.package_location && tour.package_price
        ).map((tour) => ({
          id: tour.package_id,
          title: tour.package_title,
          image: tour.package_image,
          summary: tour.package_summary,
          location: tour.package_location,
          country: tour.package_country,
          price: tour.package_price,
          days: tour.package_days,
          special: tour.package_special || "N/A",
          bestPriceGuarantee: tour.best_price_guarantee,
          freeCancelation: tour.free_cancelation,
          isPopular: tour.is_popular
        }));

        setTours(validTours);
      } catch (error) {
        console.error("Error fetching tour packages:", error.response ? error.response.data : error.message);
      }
    };

    fetchTours();
  }, []);

  const applyFilters = (tours) => {
    return tours.filter((tour) => {
      const matchType = filters.type.length === 0 || filters.type.includes(tour.type);
      const matchCountry = filters.country.length === 0 || filters.country.includes(tour.country);
      const matchDuration = filters.duration.length === 0 || filters.duration.includes(tour.days);
      const matchPrice = !filters.maxPrice || tour.price <= filters.maxPrice;
      const matchRating = !filters.rating || tour.rating >= filters.rating;
      
      return matchType && matchCountry && matchDuration && matchPrice && matchRating;
    });
  };

  const indexOfLastTour = currentPage * toursPerPage;
  const indexOfFirstTour = indexOfLastTour - toursPerPage;
  const filteredTours = applyFilters(tours);
  const currentTours = filteredTours.slice(indexOfFirstTour, indexOfLastTour);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Header />
      <section id="tours" className="tours-section">
        <div className="tours-h2">
          <h2>Explore all our tours</h2>
        </div>
        <div className="tour-container">
          <div className="filter-section">
            <h3>Filter Tours</h3>
            {/* Tour Type Filter */}
            <div className="filter-group">
              <h4>Tour Type</h4>
              {["City tours", "National park", "Lake", "Hiking", "Dance", "Food", "Music", "Culture"].map((type) => (
                <div key={type}>
                  <input
                    type="checkbox"
                    id={`type-${type}`}
                    onChange={(e) => {
                      const newTypeFilters = e.target.checked
                        ? [...filters.type, type]
                        : filters.type.filter((item) => item !== type);
                      setFilters({ ...filters, type: newTypeFilters });
                    }}
                  />
                  <label htmlFor={`type-${type}`}>{type}</label>
                </div>
              ))}
              <hr />
            </div>

            {/* Country Filter */}
            <div className="filter-group">
              <h4>Country</h4>
              {[...new Set(tours.map((tour) => tour.country))].map((country) => (
                <div key={country}>
                  <input
                    type="checkbox"
                    id={`country-${country}`}
                    checked={filters.country.includes(country)}
                    onChange={(e) => {
                      const newCountryFilters = e.target.checked
                        ? [...filters.country, country]
                        : filters.country.filter((item) => item !== country);
                      setFilters({ ...filters, country: newCountryFilters });
                    }}
                  />
                  <label htmlFor={`country-${country}`}>{country}</label>
                </div>
              ))}
              <hr />
            </div>

            {/* Duration Filter */}
            <div className="filter-group">
              <h4>Duration</h4>
              {[...new Set(tours.map((tour) => tour.days))].map((days) => (
                <div key={days}>
                  <input
                    type="checkbox"
                    id={`duration-${days}`}
                    onChange={(e) => {
                      const newDurationFilters = e.target.checked
                        ? [...filters.duration, days]
                        : filters.duration.filter((item) => item !== days);
                      setFilters({ ...filters, duration: newDurationFilters });
                    }}
                  />
                  <label htmlFor={`duration-${days}`}>{days}</label>
                </div>
              ))}
              <hr />
            </div>

            {/* Max Price Filter */}
            <div className="filter-group">
              <h4>Max Price</h4>
              <div className="input-text">
                <p>USD</p>
                <input
                  className="number-box"
                  type="number"
                  value={filters.maxPrice || ""}
                  onChange={(e) => setFilters({ ...filters, maxPrice: e.target.value })}
                />
                <p>or less</p>
              </div>
              <hr />
            </div>

            {/* Rating Filter */}
            <div className="filter-group">
              <h4>Rating</h4>
              {[4.5, 4, 3.5, 0].map((rating) => (
                <div key={rating}>
                  <input
                    type="radio"
                    id={`rating-${rating}`}
                    name="rating"
                    onChange={() => setFilters({ ...filters, rating })}
                  />
                  <label htmlFor={`rating-${rating}`}>Over {rating}</label>
                </div>
              ))}
            </div>
          </div>

          {/* Tour Listing */}
          <div className="tour-list">
            {currentTours.map((tour) => (
              <div key={tour.id} className="tour-card">
                <img
                  src={getImageUrl(tour.image)}
                  alt={tour.title}
                  className="tour-image"
                />
                <div className="tour-content">
                  <div className="tour-info">
                    <p className="tour-location">
                      {tour.location}, {tour.country}
                    </p>
                    <h3 className="tour-title">{tour.title}</h3>
                    <p className="tour-summary">{tour.summary}</p>
                    <div className="tour-special">
                    <span className="special-item">
                  Best Price Guarantee: {tour.bestPriceGuarantee === 1 ? 'Yes' : 'No'}
                          </span>
                  <span className="special-item">
                 Free Cancellation: {tour.freeCancelation === 1 ? 'Yes' : 'No'}
                 </span>
                    </div>
                  </div>
                  <div className="tour-details">
                    <p className="tour-days">
                      {parseInt(tour.days)} Days {parseInt(tour.days) - 1} Nights
                    </p>
                    <p className="tour-price">{tour.price}</p>
                    <Link to={`/tours/${tour.id}`}>
                      <button className="about-btn">Details</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
            {/* Pagination */}
            <div className="pagination">
              {Array.from({ length: Math.ceil(filteredTours.length / toursPerPage) }).map(
                (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => paginate(index + 1)}
                    className={currentPage === index + 1 ? "active" : ""}
                  >
                    {index + 1}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Tours;
