import React, { useState, useEffect } from "react";
import "../assets/styles/style.css";
import Papa from "papaparse";

// CSV 파일 경로
const csvFilePath = require("../assets/data/articles.csv");

const Articles = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // CSV 파일 읽고 파싱하는 함수
    const fetchArticles = async () => {
      const response = await fetch(csvFilePath);
      const reader = response.body.getReader();
      const result = await reader.read(); // raw array
      const decoder = new TextDecoder("utf-8");
      const csv = decoder.decode(result.value); // the csv text
      const parsedData = Papa.parse(csv, { header: true }).data; // parsed CSV data

      // isFeatured가 true인 데이터만 필터링
      const featuredArticles = parsedData.filter(article => article.isFeatured.trim() === 'true');
      setArticles(featuredArticles);
    };

    fetchArticles();
  }, []);

  return (
    <section id="articles" className="articles-section">
      <div className="container">
        <div className="articles-section-title">
          <h2>Articles</h2>
          <a href="#view-all" className="view-all-link">
            View all
          </a>
        </div>
        <div className="articles-list">
          {articles.map((article) => (
            <div key={article.article_id} className="article-card">
              <img
                src={`${process.env.PUBLIC_URL}/${article.image}`}
                alt={article.title}
                className="article-image"
              />
              <div className="article-info">
                <p className="article-date">
                  {article.date} | By {article.author}
                </p>
                <h3 className="article-title">{article.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Articles;