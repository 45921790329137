import React, { useState, useEffect } from "react";
import "../assets/styles/style.css"; // 필요한 경우 CSS 경로 수정
import Papa from "papaparse";
import { Link } from "react-router-dom";

// CSV 파일 경로
const csvFilePath = require("../assets/data/tours.csv");

const PopularTours = () => {
  const [tours, setTours] = useState([]);

  useEffect(() => {
    // CSV 파일 읽고 파싱하는 함수
    const fetchTours = async () => {
      const response = await fetch(csvFilePath);
      const reader = response.body.getReader();
      const result = await reader.read(); // raw array
      const decoder = new TextDecoder("utf-8");
      const csv = decoder.decode(result.value); // the csv text
      const parsedData = Papa.parse(csv, { header: true }).data; // parsed CSV data

      // isPopular이 true인 데이터만 필터링
      const popularTours = parsedData.filter(
        (tour) => tour.isPopular.trim().toLowerCase() === "true"
      );
      setTours(popularTours);
    };

    fetchTours();
  }, []);

  return (
    <section id="popular-tours" className="popular-tours-section">
      <div className="container">
        <div className="p-tour-list">
          {tours.map((tour) => (
            <Link
              to={`/tours/${tour.id}`}
              className="p-tour-link"
              key={tour.id}
            >
              <div className="p-tour-card">
                <img
                  src={`${process.env.PUBLIC_URL}/${tour.image}`}
                  alt={tour.title}
                  className="p-tour-image"
                />
                <div className="p-tour-info">
                  <p className="p-tour-location">{tour.location}</p>
                  <h3 className="p-tour-title">{tour.title}</h3>
                  <p className="p-tour-days">{tour.days}</p>
                  <p className="p-tour-price">{tour.price}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PopularTours;
